import React, { memo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Typography, Box, Button } from '@mui/material';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { sendFeedbackEmail } from '../../lib/helpers/emailService';
import { formatMessage } from './../../lib/helpers/formatMessage'
import './FeedbackForm.scss'
import fileUploadIcon from "../../assets/file-upload.png";

const formInitialValues = {
    name: '',
    ipn: '',
    phone: '',
    email: '',
    message: '',
    file: [],
};

const FeedbackForm = ({ variant }) => {
    const [isFormSend, setIsFormSend] = useState(false);
    const { control, register, handleSubmit, setError, watch, formState: { errors, isSubmitting, isValid }, reset } = useForm({
        mode: 'onTouched',
        defaultValues: formInitialValues,
    });
    const fileInputValue = watch('file')
    const isFileAttach = !!fileInputValue.length

    const onSubmit = async (data) => {
        const urlWithoutHash = window.location.href.split('#')[0];

        const response = await sendFeedbackEmail({ 
            ...data, 
            message: formatMessage(data.message), 
            url: urlWithoutHash
        }, variant);
        
        if (response === 'OK') {
            reset(formInitialValues);
            setIsFormSend(true)
        }
    };

    return (
        <Box
            component="form"
            className='feedback_form'
            onSubmit={handleSubmit(onSubmit)}
            onChange={() => setIsFormSend(false)}
        >

            <TextField
                fullWidth
                placeholder="Введіть ПІБ"
                variant="standard"
                {...register('name', {
                    required: "Це поле обов'язкове",
                    validate: (value) => {
                        const nameRegex = /^[А-ЯІЇЄҐ][а-яіїєґ]+\s[A-ЯІЇЄҐ][а-яіїєґ]+\s[A-ЯІЇЄҐ][а-яіїєґ]+$/;
                        return nameRegex.test(value) || "Введіть ФІО у правильному форматі";
                    },
                })}
                error={!!errors.name}
                helperText={errors.name?.message}
            />

            <TextField
                fullWidth
                placeholder="Введіть ІПН"
                variant="standard"
                {...register('ipn', {
                    required: "Це поле обов'язкове",
                    pattern: {
                        value: /^\d{10}$/,
                        message: "ІПН складається з 10 цифр"
                    }
                })}
                error={!!errors.ipn}
                helperText={errors.ipn?.message}
            />

            <Controller
                name="phone"
                control={control}
                rules={{
                    required: 'Це поле обов\'язкове',
                    validate: (value) => {
                        if (value && !matchIsValidTel(value, { onlyCountries: ['UA'] })) {
                            return 'Неправильний формат номера телефону';
                        }
                        return true;
                    },
                }}
                render={({ field: { ref, value, ...fieldProps }, fieldState }) => (
                    <MuiTelInput
                        fullWidth
                        placeholder="Введіть номер телефону"
                        defaultCountry="UA"
                        disableDropdown
                        variant="standard"
                        value={value || ''}
                        inputRef={ref}
                        error={fieldState.invalid}
                        helperText={fieldState.invalid ? 'Неправильний формат номера телефону' : null}
                        {...fieldProps}
                    />
                )}
            />

            <TextField
                fullWidth
                placeholder="Вкажіть e-mail"
                variant="standard"
                {...register('email', {
                    required: 'Це поле обов\'язкове',
                    pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: 'Неправильний формат електронної пошти',
                    },
                })}
                error={!!errors.email}
                helperText={errors.email?.message}
            />

            <TextField
                fullWidth
                placeholder="Ваше повідомлення"
                variant="standard"
                multiline
                rows={4}
                inputProps={{ maxLength: 350 }}
                {...register('message')}
            />

            <div className='file_input'>
                <input
                    accept=".jpg,.jpeg,.png,.pdf"
                    id="upload-file"
                    type="file"
                    {...register('file', {
                        onChange: (e) => {
                            const file = e.target.files[0];
                            if (file && file.size > 3 * 1024 * 1024) {
                                setError('file', { type: 'manual', message: 'Максимальний розмір файлу 3 МБ' });
                            } else {
                                setError('file', null)
                            }
                        }
                    })}
                />

                {isFileAttach && <p className='file_input__filename'>Файл: {fileInputValue[0]?.name}</p>}

                <label htmlFor="upload-file" className='file_input__label'>
                    <Button className='file_input__button' variant="contained" component="span">
                        <img src={fileUploadIcon} alt='Upload file' />
                        {isFileAttach ? "Вибрати інший" : "Завантажити файл"}
                    </Button>
                </label>

                {errors.file?.message && <p className='file_input__error'>{errors.file.message}</p>}

            </div>

            {isFormSend
                ? <Typography sx={{ m: 'auto 0' }}> Данні надіслані успішно!</Typography>
                : <button className="btn__pink"
                    title={!isValid ? 'Вам потрібно заповнити всі обов\'язкові поля перед відправкою' : ""}
                    disabled={!isValid || isSubmitting || errors.file?.message}
                    type="submit"
                >
                    Продовжити
                </button>
            }
        </Box>
    );
};

export default memo(FeedbackForm);
