import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import how_to_get_a_loan_for_studying from "../assets/blog/how_to_get_a_loan_for_studying.jpg";
import loan_for_learn_language from "../assets/blog/loan_for_learn_language.jpg";
import career_growth_in_it from "../assets/blog/career_growth_in_it.jpg";
import five_life_hacks_from_credit_expert from "../assets/blog/five_life_hacks_from_credit_expert.jpg";
import receive247_in_ukraine from "../assets/blog/receive247_in_ukraine.jpg";
import automatic_loan_from_mfo from "../assets/blog/automatic_loan_from_mfo.png";
import start_career_in_it_today from '../assets/blog/start_career_in_it_today.jpg'
import blogImg1 from "../assets/blog_img_1.png";
import blogImg2 from "../assets/blog_img_2.png";
import blogImg3 from "../assets/blog_img_3.png";
import blogImg4 from "../assets/blog_img_4.png";
import blogImg5 from "../assets/blog_img_5.png";
import blogImg6 from "../assets/blog_img_6.png";
import TermsUsingCredit from "../components/TermsUsingCredit/TermsUsingCredit";
import ScrollLink from "../lib/helpers/ScrollLink";

const Blog = () => {
    return (
        <div className="blog">  
            <div className="top_background">
                <div className="container">
                    <Header activePageName="blog" />
                    <div className="blog__wrapper">
                        <div className="title">Блог</div>
                        <div className="blog__list">
                        <div className="blog__block">
                                <div className="blog__item">
                                    <div className="blog__img">
                                        <img
                                            src={start_career_in_it_today}
                                            alt="start career in it today"
                                        />
                                    </div>
                                    <div className="blog__title">
                                        Почніть кар’єру в IT вже сьогодні: Навчання і фінансові рішення для старту
                                    </div>
                                    <div className="blog__desc">
                                        Сучасний світ змінюється настільки швидко, що часом здається, що ти вже «не встигаєш за поїздом»
                                    </div>
                                </div>
                                <ScrollLink to="/blog/start-career-in-it-today" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                            <div className="blog__block">
                                <div className="blog__item">
                                    <div className="blog__img">
                                        <img
                                            src={automatic_loan_from_mfo}
                                            alt="automatic loan from mfo"
                                        />
                                    </div>
                                    <div className="blog__title">
                                        Як подати заявку на автоматичний кредит у МФО
                                    </div>
                                    <div className="blog__desc">
                                        Покрокова інструкція для швидкого та зручного оформлення позики
                                    </div>
                                </div>
                                <ScrollLink to="/blog/how-apply-for-automatic-loan-from-mfo" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                            <div className="blog__block">
                                <div className="blog__item">
                                    <div className="blog__img">
                                        <img
                                            src={receive247_in_ukraine}
                                            alt="receive247 in Ukraine"
                                        />
                                    </div>
                                    <div className="blog__title">
                                        Чи реально в Украіні отримати цілодобово онлайн-кредит на картку: умови, переваги та рекомендації
                                    </div>
                                    <div className="blog__desc">
                                        Це питання часто постає у користувачів, які шукають максимально швидке й зручне фінансове рішення. Спробуймо розібратися.
                                    </div>
                                </div>
                                <ScrollLink to="/blog/receive-247-in-ukraine" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                            <div className="blog__block">
                                <div className="blog__item">
                                    <div className="blog__img">
                                        <img
                                            src={five_life_hacks_from_credit_expert}
                                            alt="five life hacks from credit expert"
                                        />
                                    </div>
                                    <div className="blog__title">
                                        5 лайфхаків від кредитного експерта щодо онлайн-кредитів
                                    </div>
                                    <div className="blog__desc">
                                        Протягом багатьох років роботи у фінансових компаніях я маю можливість спостерігати, як люди беруть і погашають онлайн-кредити, як вони керують своїми грошима та які помилки найчастіше роблять.
                                    </div>
                                </div>
                                <ScrollLink to="/blog/five-life-hacks-from-credit-expert" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                            <div className="blog__block">
                                <div className="blog__item">
                                    <div className="blog__img">
                                        <img
                                            src={career_growth_in_it}
                                            alt="career growth in it"
                                        />
                                    </div>
                                    <div className="blog__title">
                                        Кар'єрний Ріст у Сфері ІТ
                                    </div>
                                    <div className="blog__desc">
                                        Технології все більше проникають у різні галузі, дозволяючи автоматизувати виробничі процеси, підвищувати продуктивність, знижувати витрати та загалом підвищувати ефективність бізнесу.
                                    </div>
                                </div>
                                <ScrollLink to="/blog/career-growth-in-it" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                            <div className="blog__block">
                                <div className="blog__item">
                                    <div className="blog__img">
                                        <img
                                            src={loan_for_learn_language}
                                            alt="loan for learn language"
                                        />
                                    </div>
                                    <div className="blog__title">
                                        Навчання мовному курсу
                                    </div>
                                    <div className="blog__desc">
                                        У сучасному світі, де глобалізація та міжнародні зв'язки стають дедалі важливішими, знання іноземних мов відкриває безліч нових можливостей.
                                    </div>
                                </div>
                                <ScrollLink to="/blog/loan-learn-language" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                            <div className="blog__block">
                                <div className="blog__item">
                                    <div className="blog__img">
                                        <img
                                            src={how_to_get_a_loan_for_studying}
                                            alt="how to get a loan for studying"
                                        />
                                    </div>
                                    <div className="blog__title">
                                        Навчання комп'ютерного курсу
                                    </div>
                                    <div className="blog__desc">
                                        У сучасному світі цифрових технологій, комп'ютерна грамотність стає невід'ємною частиною успішної кар'єри та особистого розвитку.
                                    </div>
                                </div>
                                <ScrollLink to="/blog/loan-for-studying" className="btn btn__pink">Читати далі</ScrollLink>
                            </div>
                        </div>
                        {/* <div className="blog__btn">
                            <button className="btn btn__green">Більше статей</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <TermsUsingCredit />
            <Footer />
        </div>
    );
};

export default Blog;