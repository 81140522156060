import Input from '@mui/material/Input'
import { Slider } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import useCalculate from '../../../lib/hooks/useCalculate'
import Header from '../../../components/Header/Header'
import toFixNumber from '../../../lib/helpers/toFixNumber'
import Button from '../../../components/Common/Button'
import informationAboutTheServiceAdditionFile from '../../../files/Інформація_про_послугу_з_надання_мікрокредитів.pdf'

const PaydayLoanCalculate = ({ loansNotIssued = false }) => {
	const {
		total,
		principal,
		interest,
		creditPolicyInterest,
		loanTerm,
		setLoanTerm,
		setLoanAmount,
		creditPolicies,
		createShopRequest,
		promocode,
		setPromocode,
		sendPromoCodeLoan
	} = useCalculate({
		creditProductId: 4
	})

	return (
		<div className="top_background__payday">
			<div className="container">
				<Header activePageName="/" />
				<div className="payday_loan__wrapper">
					<div className="payday_loan__left">
						<div className="title">Кредит до зарплати</div>
						<div className="desc">Онлайн кредит на картку за 5 хвилин</div>
					</div>
					<div className="car_loan__calculate">
						<div className="car_loan__calculate_left loan_calculate">
							<div className="loan_calculate__slider">
								<div className="loan_calculate__slider_title">Сума кредиту, грн</div>
								<Slider
									defaultValue={creditPolicies?.loan_amount_limit_from ? creditPolicies?.loan_amount_limit_from : 0}
									aria-label="Default"
									valueLabelDisplay="auto"
									min={creditPolicies?.loan_amount_limit_from ? creditPolicies?.loan_amount_limit_from : 0}
									max={creditPolicies?.loan_amount_limit_to ? creditPolicies?.loan_amount_limit_to : 0}
									onChange={(e) => setLoanAmount(e.target.value)}
									step={100}
								/>
								<div className="loan_calculate__slider_block">
									<div className="loan_calculate__slider_desc">
										{creditPolicies?.loan_amount_limit_from
											? creditPolicies?.loan_amount_limit_from
											: 0
										} ₴
									</div>
									<div className="loan_calculate__slider_desc">
										{creditPolicies?.loan_amount_limit_to
											? creditPolicies?.loan_amount_limit_to
											: 0
										} ₴
									</div>
								</div>
							</div>
							<div className="loan_calculate__slider">
								<div className="loan_calculate__slider_title">Строк кредиту, днів</div>
								<Slider
									defaultValue={creditPolicies?.loan_term_limit_from ? creditPolicies?.loan_term_limit_from : 0}
									aria-label="Default"
									valueLabelDisplay="auto"
									min={creditPolicies?.loan_term_limit_from ? creditPolicies?.loan_term_limit_from : 0}
									max={creditPolicies?.loan_term_limit_to ? creditPolicies?.loan_term_limit_to : 0}
									onChange={(e) => setLoanTerm(e.target.value)}
								/>
								<div className="loan_calculate__slider_block">
									<div className="loan_calculate__slider_desc">
										{creditPolicies?.loan_term_limit_from
											? creditPolicies?.loan_term_limit_from
											: 0
										} дн.
									</div>
									<div className="loan_calculate__slider_desc">
										{creditPolicies?.loan_term_limit_to
											? creditPolicies?.loan_term_limit_to
											: 0
										} дн.
									</div>
								</div>
							</div>
							<div className="loan_calculate__promocode">
								<div className="loan_calculate__slider_title">Є промокод?</div>
								<div className="loan_calculate__promocode_block">
									<Input
										size="small"
										placeholder="Введіть промокод"
										id="standard-basic"
										variant="outlined"
										value={promocode}
										onChange={(e) => setPromocode(e.target.value)}
										sx={{
											'& .MuiInputBase-input::placeholder': {
												color: 'black',
												opacity: 1
											},
											'&:before': {
												borderBottomColor: promocode ? '#f07c93' : '#000'
											},
											'&:hover:not(.Mui-disabled):before': {
												borderBottomColor: '#0c6a6c'
											},
											'&.Mui-focused:after': {
												borderBottomColor: '#f07c93'
											}
										}}
									/>
									<button className="btn btn__green" onClick={sendPromoCodeLoan}>Застосувати</button>
								</div>

							</div>
						</div>
						<div className="car_loan__calculate_right loan_calculate__right">
							<div className="loan_calculate__right__block">
								<div className="loan_calculate__right__title">Загальні витрати за кредитом, грн</div>
								<div className="loan_calculate__right__number">{toFixNumber(principal)} грн</div>
							</div>
							<div className="loan_calculate__right__block">
								<div className="loan_calculate__right__title">Сума платежу за розрахунковий період, грн</div>
								<div className="loan_calculate__right__number">{toFixNumber(interest)} грн</div>
							</div>
							<div className="loan_calculate__right__block">
								<div className="loan_calculate__right__title">Загальна вартість кредиту, грн</div>
								<div className="loan_calculate__right__number">{toFixNumber(total)} грн</div>
							</div>
							<div className="loan_calculate__right__block">
								<div className="loan_calculate__right__title">Реальна річна процентна ставка, %</div>
								<div className="loan_calculate__right__number">
									{creditPolicyInterest ? toFixNumber(creditPolicyInterest * 100) : '---'}%
								</div>
							</div>
							<div className="loan_calculate__right__block">
								<div className="loan_calculate__right__title">Дата оплати</div>
								<div className="loan_calculate__right__number">
									{loanTerm ? moment().add(loanTerm, 'days').format("DD.MM.YYYY") : '---'}
								</div>
							</div>
							<Button onClick={createShopRequest} pink className='loan_calc_apply_btn'>
								Оформити кредит
							</Button>
						</div>
						<div className="car_loan__calculate_info">
							{loansNotIssued && (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										color: 'red',
										fontSize: '18px',
										fontWeight: 700,
										textDecorations: 'uppercase',
										marginBottom: '10px',
										textTransform: 'uppercase'
									}}
								>
									Кредити тимчасово не видаються.
								</div>
							)}
							<Link to={informationAboutTheServiceAdditionFile} className="car_loan__calculate_info__link" target="_blank">
								Інформація про істотні характеристики послуги з надання мікрокредитів
							</Link>
							Фінансова установа розміщує на сторінці з інформацією про послугу з надання споживчого кредиту приклади результатів розрахунків калькулятора, до яких включає інформацію про всі припущення, використані для розрахунку загальних витрат за послугою з надання споживчого кредиту
							{/* <div style={{ display: "flex", alignItems: 'center', gap: "10px", marginTop: "10px" }}>
                                    <img src={viberIcon} alt="viber" style={{ width: "36px", cursor: "pointer" }} />
                                    <img src={kyivstarIcon} alt="kyivstar" style={{ width: "36px", cursor: "pointer" }} />
                                    <img src={lifecellIcon} alt="lifecel" style={{ height: "36px", cursor: "pointer" }} />
                                    <img src={phoneCallIcon} alt="phone call" style={{ width: "36px", cursor: "pointer" }} />
                                </div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PaydayLoanCalculate;