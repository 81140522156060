import Input from '@mui/material/Input'
import {
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Slider,
	Table, TableBody, TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import useCalculate from '../../../lib/hooks/useCalculate'
import Header from '../../../components/Header/Header'
import toFixNumber from '../../../lib/helpers/toFixNumber'
import Button from '../../../components/Common/Button'
import informationAboutTheServiceAdditionFile from '../../../files/Інформація_про_послугу_з_надання_мікрокредитів.pdf'
import {roundNumber} from "../../../lib/helpers/roundValues";
import informationCreditFamilyLoan
	from "../../../files/Інформація_про_послугу_надання_Сімейного_кредиту.pdf";

const FamilyLoanCalculate = ({ loansNotIssued = false }) => {
	const {
		total,
		principal,
		interest,
		apr,
		setLoanTerm,
		setLoanAmount,
		creditPolicies,
		createShopRequest,
		promocode,
		setPromocode,
		sendPromoCodeLoan,
		paymentScheduleCalculate
	} = useCalculate({
		creditProductId: 12,
		typeSendLoanTerm: 'month',
		sendCalculate: false
	})

	return (
		<div className="top_background__family_loan">
			<div className="container">
				<Header activePageName="family-loan" />
				<div className="study_loan__wrapper">
					<div className="payday_loan__left">
						<div>
							<h1 className="title">Сімейний кредит</h1>
							<p className="study_loan__title">Доступний шлях до кращого життя родини</p>
						</div>

						<Button onClick={createShopRequest} pink>
							ЗАРЕЄСТРУВАТИСЬ
						</Button>
					</div>
					<div className="car_loan__calculate">
						{/* left */}
						<div className="car_loan__calculate_left loan_calculate" style={{ paddingTop: '30px' }}>
							<div className="loan_calculate__slider" style={{ marginTop: 0 }}>
								<div className="loan_calculate__slider_title">Сума кредиту, грн</div>
								<Slider
									defaultValue={creditPolicies?.loan_amount_limit_from ? creditPolicies?.loan_amount_limit_from : 0}
									aria-label="Default"
									valueLabelDisplay="auto"
									min={creditPolicies?.loan_amount_limit_from ? creditPolicies?.loan_amount_limit_from : 0}
									max={creditPolicies?.loan_amount_limit_to ? creditPolicies?.loan_amount_limit_to : 0}
									onChange={(e) => setLoanAmount(e.target.value)}
									step={100}
								/>
								<div className="loan_calculate__slider_block">
									<div className="loan_calculate__slider_desc">
										{creditPolicies?.loan_amount_limit_from
											? creditPolicies?.loan_amount_limit_from
											: 0
										} ₴
									</div>
									<div className="loan_calculate__slider_desc">
										{creditPolicies?.loan_amount_limit_to
											? creditPolicies?.loan_amount_limit_to
											: 0
										} ₴
									</div>
								</div>
							</div>
							<div className="loan_calculate__slider">
								<div className="loan_calculate__slider_title">Строк кредиту, місяців</div>
								<Slider
									defaultValue={creditPolicies?.loan_term_limit_from ? creditPolicies?.loan_term_limit_from : 0}
									aria-label="Default"
									valueLabelDisplay="auto"
									min={creditPolicies?.loan_term_limit_from
										? creditPolicies?.loan_term_limit_from < 30 ? 1 : +(creditPolicies?.loan_term_limit_from / 30).toFixed()
										: 0}
									max={creditPolicies?.loan_term_limit_to
										? creditPolicies?.loan_term_limit_to < 30 ? 1 : +(creditPolicies?.loan_term_limit_to / 30).toFixed()
										: 0}
									onChange={(e) => setLoanTerm(e.target.value)}
								/>
								<div className="loan_calculate__slider_block">
									<div className="loan_calculate__slider_desc">
										{creditPolicies?.loan_term_limit_from
											? creditPolicies?.loan_term_limit_from < 30 ? 1 : +(creditPolicies?.loan_term_limit_from / 30).toFixed()
											: 0
										} міс.
									</div>
									<div className="loan_calculate__slider_desc">
										{creditPolicies?.loan_term_limit_to
											? creditPolicies?.loan_term_limit_to < 30 ? 1 : +(creditPolicies?.loan_term_limit_to / 30).toFixed()
											: 0
										} міс.
									</div>
								</div>
							</div>

							<FormControl sx={{ marginTop: '25px' }} fullWidth>
								<InputLabel
									id="how-get-tranches-select-label"
									sx={{
										'&.Mui-focused': {
											color: '#107275'
										}
									}}
								>
									Частота оплати
								</InputLabel>
								<Select
									labelId="how-get-tranches-select-label"
									id="how-get-tranches"
									value={1}
									label="Частота оплати"
									onChange={(e) => {}}
									sx={{
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: '#107275'
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: '#107275'
										},
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#107275'
										}
									}}
								>
									<MenuItem value={1}>1 раз на місяць</MenuItem>
									<MenuItem value={3}>Раз на 3 місяці</MenuItem>
									<MenuItem value={6}>Раз на 6 місяців</MenuItem>
								</Select>
							</FormControl>

							<FormControl sx={{ marginTop: '25px' }} fullWidth>
								<InputLabel
									id="prepayment-select-label"
									sx={{
										'&.Mui-focused': {
											color: '#107275'
										}
									}}
								>
									Передоплата, %
								</InputLabel>
								<Select
									labelId="prepayment-select-label"
									id="prepayment"
									value={1}
									label="Передоплата, %"
									onChange={(e) => {}}
									sx={{
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: '#107275'
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: '#107275'
										},
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#107275'
										}
									}}
								>
									<MenuItem value={1}>0%</MenuItem>
									<MenuItem value={2}>15%</MenuItem>
									<MenuItem value={3}>20%</MenuItem>
									<MenuItem value={4}>інше</MenuItem>
								</Select>
							</FormControl>

							<FormControl sx={{ marginTop: '25px' }} fullWidth>
								<Input
									size="small"
									placeholder="Введіть промокод"
									id="standard-basic"
									variant="outlined"
									value={promocode}
									onChange={(e) => setPromocode(e.target.value)}
									sx={{
										'& .MuiInputBase-input::placeholder': {
											color: 'black',
											opacity: 1
										},
										'&:before': {
											borderBottomColor: promocode ? '#f07c93' : '#000'
										},
										'&:hover:not(.Mui-disabled):before': {
											borderBottomColor: '#0c6a6c'
										},
										'&.Mui-focused:after': {
											borderBottomColor: '#f07c93'
										}
									}}
								/>
							</FormControl>
							<FormControl sx={{ marginTop: '25px' }} fullWidth>
								<Button onClick={sendPromoCodeLoan} green>Показати графік платежів</Button>
							</FormControl>
						</div>

						{/* right */}
						<div className="car_loan__calculate_right loan_calculate__right">
							<div className="loan_calculate__right__block">
								<div className="loan_calculate__right__title">Загальні витрати за кредитом, грн</div>
								<div className="loan_calculate__right__number">{toFixNumber(principal)} грн</div>
							</div>
							<div className="loan_calculate__right__block">
								<div className="loan_calculate__right__title">Сума платежу за розрахунковий період, грн</div>
								<div className="loan_calculate__right__number">{toFixNumber(interest)} грн</div>
							</div>
							<div className="loan_calculate__right__block">
								<div className="loan_calculate__right__title">Загальна вартість кредиту, грн</div>
								<div className="loan_calculate__right__number">{toFixNumber(total)} грн</div>
							</div>
							<div className="loan_calculate__right__block">
								<div className="loan_calculate__right__title">Реальна річна процентна ставка, %</div>
								<div className="loan_calculate__right__number">
									{toFixNumber(90)}%
									{/* {toFixNumber(apr * 100)}% */}
								</div>
							</div>
							<a href="#application-form-scroll">
								<Button onClick={() => {}} style={{ margin: '10px 0', width: '100%' }} pink className='loan_calc_apply_btn'>
									Залишити заявку
								</Button>
							</a>
						</div>
						<div className="car_loan__calculate_info">
							{paymentScheduleCalculate?.length > 0 && (
								<>
									<TableContainer component={Paper}>
										<Table sx={{ minWidth: 650 }} aria-label="simple table">
											<TableHead>
												<TableRow>
													<TableCell sx={{ fontWeight: 'bold' }}>Дата</TableCell>
													<TableCell sx={{ fontWeight: 'bold' }} align="right">Сума кредиту</TableCell>
													<TableCell sx={{ fontWeight: 'bold' }} align="right">Відсотки по кредиту</TableCell>
													<TableCell sx={{ fontWeight: 'bold' }} align="right">Комісія</TableCell>
													<TableCell sx={{ fontWeight: 'bold' }} align="right">Всього</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{paymentScheduleCalculate.map(({ id, date, principal, interest, commission }) => (
													<TableRow
														key={`payment-schedule-calculate-item-${id}`}
														sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
													>
														<TableCell component="th" scope="row">{moment(date).format('DD.MM.YYYY')}</TableCell>
														<TableCell align="right">{roundNumber(principal)}</TableCell>
														<TableCell align="right">{roundNumber(interest)}</TableCell>
														<TableCell align="right">{roundNumber(commission)}</TableCell>
														<TableCell align="right">{roundNumber(+principal + +interest + +commission)}</TableCell>
													</TableRow>
												))}
												<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
													<TableCell sx={{ fontWeight: 'bold' }} component="th" scope="row">Всього</TableCell>
													<TableCell align="right">
														{paymentScheduleCalculate.reduce((acc, { principal }) => roundNumber(+principal + +acc), 0)}
													</TableCell>
													<TableCell align="right">
														{paymentScheduleCalculate.reduce((acc, { interest }) => roundNumber(+interest + +acc), 0)}
													</TableCell>
													<TableCell align="right">
														{paymentScheduleCalculate.reduce((acc, { commission }) => roundNumber(commission + +acc), 0)}
													</TableCell>
													<TableCell align="right">
														{
															paymentScheduleCalculate.reduce((acc, { principal, interest, commission }) =>
																	roundNumber(+principal + +interest + +commission + +acc),
																0)
														}
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
									<Button onClick={createShopRequest} style={{ margin: '10px 0', width: '100%' }} pink className='loan_calc_apply_btn'>
										Оформити кредит
									</Button>
								</>
							)}
							{loansNotIssued && (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										color: 'red',
										fontSize: '18px',
										fontWeight: 700,
										textDecorations: 'uppercase',
										marginBottom: '10px',
										textTransform: 'uppercase'
									}}
								>
									Кредити тимчасово не видаються.
								</div>
							)}
							<Link to={informationCreditFamilyLoan} className="car_loan__calculate_info__link" target="_blank">
								Інформація про істотні характеристики послуги надання сімейного кредиту
							</Link>
							Фінансова установа розміщує на сторінці з інформацією про послугу з надання споживчого кредиту приклади результатів розрахунків калькулятора, до яких включає інформацію про всі припущення, використані для розрахунку загальних витрат за послугою з надання споживчого кредиту
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FamilyLoanCalculate;