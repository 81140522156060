import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import Blog from "./pages/Blog";
import Home from "./pages/Home";
import CarLoan from "./pages/CarLoan";
import PaydayLoan from "./pages/PaydayLoan";
import BusinessLoan from "./pages/BusinessLoan";
import InsuranceServices from "./pages/InsuranceServices";
import MedicalServices from "./pages/MedicalServices";
import StudyLoan from "./pages/StudyLoan";
import PersonalLoan from "./pages/PersonalLoan";
import RefinancingLoan from "./pages/RefinancingLoan";
import Stock from "./pages/Stock";
import FaqPage from "./pages/FaqPage";
import LoyaltyProgram from "./pages/LoyaltyProgram";
import CreditForPurchase from "./pages/CreditForPurchase";
import NeedMoney from "./pages/StockPages/NeedMoney";
import TestStudentLoan from "./pages/TestStudentLoan";
import MoneyBeforeSalary from "./pages/StockPages/MoneyBeforeSalary";
import MoneyAutumn from "./pages/StockPages/MoneyAutumn";
import HowToGetLoanForStudying from "./pages/BlogPages/HowToGetLoanForStudying";
import SimpleSteps from "./pages/StockPages/SimpleSteps";
import RomanceCost from "./pages/StockPages/RomanceCost";
import DocumentsAreRequired from "./pages/StockPages/DocumentsAreRequired";
import MoneyAutumnContinues from "./pages/StockPages/MoneyAutumnContinues";
import TeacherSchool from "./pages/StockPages/TeacherSchool";
import InterestRate from "./pages/StockPages/InterestRate";
import BlackFriday from "./pages/StockPages/BlackFriday";
import HowToGetLoanLearnLanguage from "./pages/BlogPages/HowToGetLoanLearnLanguage";
import FamilyLoan from "./pages/FamilyLoan";
import CareerGrowthInIt from "./pages/BlogPages/CareerGrowthInIt";
import BlogFiveLifeHacksFromCreditExpert from "./pages/BlogPages/BlogFiveLifeHacksFromCreditExpert";
import ReviewsPage from "./pages/ReviewsPage";
import ReceiveInUkraine from "./pages/BlogPages/ReceiveInUkraine";
import HowApplyForAutomaticLoanFromMfo from "./pages/BlogPages/HowApplyForAutomaticLoanFromMfo";
import StartCareerInItToday from "./pages/BlogPages/StartCareerInItToday";
import HappyHolidayDiscount2024 from "./pages/StockPages/HappyHolidayDiscount2024";

const routers = createBrowserRouter([
    {
        path: "/",
        element: <PaydayLoan />
    },
    {
        path: "/home",
        element: <Home />
    },
    {
        path: "/about-us",
        element: <AboutUs />
    },
    {
        path: "/blog",
        element: <Blog />
    },
    {
        path: "/blog/loan-for-studying",
        element: <HowToGetLoanForStudying />
    },
    {
        path: "/blog/loan-learn-language",
        element: <HowToGetLoanLearnLanguage />
    },
    {
        path: "/blog/career-growth-in-it",
        element: <CareerGrowthInIt />
    },
    {
        path: "/blog/five-life-hacks-from-credit-expert",
        element: <BlogFiveLifeHacksFromCreditExpert />
    },
    {
        path: "/blog/receive-247-in-ukraine",
        element: <ReceiveInUkraine />
    },
    {
        path: "/blog/how-apply-for-automatic-loan-from-mfo",
        element: <HowApplyForAutomaticLoanFromMfo />
    },
    {
        path: "/blog/start-career-in-it-today",
        element: <StartCareerInItToday />
    },
    {
        path: "/car-loan",
        element: <CarLoan />
    },
    {
        path: "/credit-for-purchase",
        element: <CreditForPurchase />
    },
    // {
    //     path: "/business-loan",
    //     element: <BusinessLoan />
    // },
    // {
    //     path: "/insurance-services",
    //     element: <InsuranceServices />
    // },
    // {
    //     path: "/medical-services",
    //     element: <MedicalServices />
    // },
    {
        path: "/student-loan",
        element: <StudyLoan />
    },
    {
        path: "/family-loan",
        element: <FamilyLoan />
    },
    // {
    //     path: "/personal-loan",
    //     element: <PersonalLoan />
    // },
    // {
    //     path: "/refinancing-loan",
    //     element: <RefinancingLoan />
    // },
    {
        path: "/promoactionnews",
        element: <Stock />
    },
    {
        path: "/promoactionnews/need-money",
        element: <NeedMoney />
    },
    {
        path: "/promoactionnews/money-before-salary",
        element: <MoneyBeforeSalary />
    },
    {
        path: "/promoactionnews/money-autumn",
        element: <MoneyAutumn />
    },
    {
        path: "/promoactionnews/4-simple-steps",
        element: <SimpleSteps />
    },
    {
        path: "/promoactionnews/romance-cost",
        element: <RomanceCost />
    },
    {
        path: "/promoactionnews/documents-are-required",
        element: <DocumentsAreRequired />
    },
    {
        path: "/promoactionnews/money-autumn-continues",
        element: <MoneyAutumnContinues />
    },
    {
        path: "/promoactionnews/teacher-school",
        element: <TeacherSchool />
    },
    {
        path: "/promoactionnews/interest-rate",
        element: <InterestRate />
    },
    {
        path: "/promoactionnews/black-friday",
        element: <BlackFriday />
    },
    {
        path: "/promoactionnews/happy-holiday-24-12-2024",
        element: <HappyHolidayDiscount2024 />
    },
    {
        path: "/uk/pitannya-ta-vidpovidi",
        element: <FaqPage />
    },
    {
        path: "/loyalty-program",
        element: <LoyaltyProgram />
    },
    {
        path: "/testing-student-loan",
        element: <TestStudentLoan />
    },
    {
        path: "/reviews",
        element: <ReviewsPage />
    },
    {
        path: "*",
        element: <Navigate to="/" replace />
    }
]);

export default routers