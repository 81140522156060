import React from 'react';
import Header from "../../components/Header/Header";
import ScrollLink from "../../lib/helpers/ScrollLink";
import TermsUsingCredit from "../../components/TermsUsingCredit/TermsUsingCredit";
import Footer from "../../components/Footer/Footer";

const HappyHolidayDiscount2024 = () => {
    return (
        <div className="stock">
            <div className="top_background" style={{ backgroundImage: 'none' }}>
                <div className="container">
                    <Header activePageName="promoactionnews" />
                    <div className="blog__wrapper">
                        <h1 className="title">Різдвяно-новорічна знижка на кредит -50%</h1>
                        <div className="blog__item" style={{ width: '100%' }}>
                            <div className="blog__desc">
                                Оформіть вигідну швидку позику онлайн, і гроші на подарунки та святкування будуть у вас на картці впродовж кількох хвилин <ScrollLink to="/">https://groshi247.com/</ScrollLink>. Заповнюючи заявку на кредит, застосуйте промокод <b>GROSHI50</b>.
                                <br />
                                <br />
                                __
                                <br />
                                Акція діє до 10.01.2025 р. на всій підконтрольній Україні території, окрім тимчасово окупованих та оточених регіонів, а також районів, де ведуться бойові дії. <a href="https://cutt.ly/RebIfU0W" target="_blank">Інформація про істотні характеристики послуги з надання мікрокредитів</a> та <a href="https://cutt.ly/henryljy" target="_blank">Попередження про невиконання умов договору кредитування</a>.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TermsUsingCredit />
            <Footer />
        </div>
    );
};

export default HappyHolidayDiscount2024;