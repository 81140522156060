import React, { memo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Checkbox, FormControlLabel, FormControl, FormGroup, FormHelperText, Typography } from '@mui/material';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { sendApplicationEmail } from '../../lib/helpers/emailService';
import { useState } from 'react';
import './ApplicationForm.scss'

const formInitialValues = {
    name: '',
    phone: '',
    email: '',
    agree: false,
    agreeAds: false,
}

const ApplicationForm = () => {
    const [isFormSend, setIsFormSend] = useState(false)
    const {
        control,
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,

    } = useForm({
        mode: 'onTouched',
        defaultValues: formInitialValues
    });

    const onSubmit = async (data) => {
        const urlWithoutHash = window.location.href.split('#')[0];

        const response = await sendApplicationEmail({...data, url: urlWithoutHash});
        if (response === "OK") {
            reset(formInitialValues)
            setIsFormSend(true)
        }
    }

    return (
        <form
            className='application_form'
            onSubmit={handleSubmit(onSubmit)}
            onChange={() => setIsFormSend(false)}
            id="application-form-scroll"
        >

            <div className="title">Заповніть форму, щоб отримати консультацію</div>

            <FormControl fullWidth>
                <TextField
                    size="small"
                    variant="standard"
                    placeholder="Прізвище, ім'я, по батькові"
                    {...register('name', {
                        required: 'Це поле обов\'язкове', validate: (value) => {
                            // ФИО regExp
                            const nameRegex = /^[А-ЯІЇЄҐ][а-яіїєґ]+\s[A-ЯІЇЄҐ][а-яіїєґ]+\s[A-ЯІЇЄҐ][а-яіїєґ]+$/;
                            return nameRegex.test(value) || 'Введіть Прізвище Ім\'я По батькові у правильному форматі';
                        }
                    })}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                />
                {!errors.name?.message && <FormHelperText>
                    Введіть точно так, як в паспорті
                </FormHelperText>}
            </FormControl>

            <FormControl sx={{ width: { xs: '100%', sm: '48%' } }}>
                <Controller
                    name="phone"
                    control={control}
                    rules={{
                        required: ' ',
                        validate: (value) => {
                            if (value && !matchIsValidTel(value, { onlyCountries: ['UA'] })) {
                                return "Неправильний формат номера телефону";
                            }
                            return true;
                        }
                    }}
                    render={({ field: { ref, value, ...fieldProps }, fieldState }) => (
                        <>
                            <MuiTelInput
                                // Change if need more Countries
                                onlyCountries={['UA']}
                                defaultCountry='UA'
                                disableDropdown
                                // 
                                size='small'
                                variant="standard"
                                placeholder='+380 95 123 45 67'
                                value={value || ''}
                                inputRef={ref}
                                helperText={fieldState.invalid ? "Неправильний формат номера телефону" : null}
                                error={fieldState.invalid}
                                {...fieldProps}
                            />
                            {!fieldState.invalid && <FormHelperText>
                                Для повідомлення рішення по заявці
                            </FormHelperText>}
                        </>
                    )}
                />
            </FormControl>

            <FormControl sx={{ width: { xs: '100%', sm: '48%' } }}>
                <TextField
                    size="small"
                    variant="standard"
                    {...register('email', {
                        required: 'Це поле обов\'язкове',
                        pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: 'Неправильний формат електронної пошти'
                        }
                    })}
                    placeholder='Електронна пошта'
                    error={!!errors.email}
                    helperText={errors.email?.message}
                />
                {!errors.email?.message && <FormHelperText>
                    Для отримання інформації по кредиту
                </FormHelperText>}
            </FormControl>

            <FormGroup sx={{ width: '100%', gap: '10px' }}>
                <div>
                    <FormControlLabel
                        control={<Checkbox {...register('agree', { required: 'Це поле обов\'язкове' })} />}
                        label="Я даю свою згоду на обробку та використання моїх персональних даних*."

                    />
                    {errors.agree && <FormHelperText sx={{ marginTop: 0, color: 'red' }}>{errors.agree.message}</FormHelperText>}
                </div>
                <FormControlLabel
                    control={<Checkbox {...register('agreeAds')} />}
                    label="Я даю згоду на отримання розсилки рекламних оголошень та інших пропозицій компанії."
                />
            </FormGroup>


            {isFormSend
                ? < Typography > Данні надіслані успішно!</Typography>
                : <button className="btn btn__pink"
                    title={!isValid ? 'Вам потрібно погодитися з умовами та заповнити всі поля перед відправкою' : ""}
                    disabled={!isValid || isSubmitting}
                    type="submit"
                >
                    Продовжити
                </button>
            }

        </form>
    );
};

export default memo(ApplicationForm)
