import React, { useState } from 'react'

const PaydayLoanGiveCredit = () => {
	const [showTerm, setShowTerm] = useState(false)

	return (
		<div className="terms_using_credit">
			<div className="container">
				<div className="title">Беріть кредит відповідально</div>
				<div className="terms_using_credit__wrapper">
					<div className={`terms_using_credit__info ${showTerm ? 'active' : ''}`}>
						Перед оформленням кредиту завжди слід оцінити свої потреби та можливості, а також ретельно обміркувати, чи доцільно брати кредит.
						<br/>
						<br/>
						Зважте, чи відповідають умови кредитного договору Вашим інтересам та фінансовому становищу. Обміркуйте можливі зміни у Вашому житті (збільшення витрат, наприклад, створення сім'ї або зниження доходів, наприклад, вихід на пенсію, зниження заробітної плати або втрата роботи, погіршення стану здоров’я тощо), і як вони можуть вплинути на здатність повернути кредит.
						<br/>
						<br/>
						Заповнюючи заявку на кредит, завжди надавайте тільки достовірну інформацію щодо свого фінансового становища, доходів, зобов'язань тощо.
						<br/>
						<br/>
						Уважно ознайомтеся з кредитним договором. Ретельно обміркуйте кожен пункт і переконайтеся, що запропоновані умови не суперечать Вашим інтересам і відповідають Вашим можливостям. Якщо виникнуть питання, Ви можете звернутись за роз’ясненнями в чат на сайті або за телефоном <a href="tel:+380800207613">0 800 207 613</a>.
						<br/>
						<br/>
						Нижче наведено деякі важливі пункти договору про споживчий кредит:
						<br/>
						<br/>
						- Товариство з обмеженою відповідальністю «Гроші 247» має право вносити зміни до укладених зі Споживачами договорів про споживчий кредит тільки за взаємною згодою сторін у спосіб та на умовах визначених договором про споживчий кредит;
						<br/>
						<br/>
						- Споживач має право відмовитися від отримання рекламних матеріалів Товариства засобами дистанційних каналів комунікації, шляхом надсилання відповідного повідомлення Товариству;
						<br/>
						<br/>
						- Можливі витрати на сплату Споживачем платежів за користування споживчим кредитом в залежності від обраного Споживачем способу сплати;
						<br/>
						<br/>
						- Споживач має право на продовження (пролонгації) строку погашення споживчого кредиту (строку виконання грошового зобов’язання)/ строку кредитування/ строку дії договору про споживчий кредит за згодою Товариства. Ці дії на стандартних умовах здійснюються в Особистому кабінеті Споживача без змін або зі зміною умов попередньо укладеного договору в бік погіршення для Споживача із зазначенням переліку та цифрового значення умов, що підлягають зміні та залежить від виду договору про споживче кредитування. Для отримання індивідуальних умов продовження строку погашення споживчого кредиту, внесення інших змін в договір споживчого кредиту Позичальник повинен направити письмове звернення Товариству із зазначенням бажаних умов зміни умов кредитування. Товариство розглядає таке звернення відповідно до внутрішніх процедур Товариства і повідомляє клієнту своє рішення;
						<br/>
						<br/>
						- Споживач повинен негайно інформувати Товариство про несанкціонований доступ або зміну інформації про себе в системах дистанційного обслуговування Товариства з обмеженою відповідальністю «Гроші 247».
					</div>
					<div className="terms_using_credit__border" />
					<button className="btn btn__pink" onClick={() => setShowTerm(!showTerm)}>
						{showTerm ? 'Сховати' : 'Читати повністю'}
					</button>
				</div>
			</div>
		</div>
	)
}

export default PaydayLoanGiveCredit;