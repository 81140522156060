export const studyLoanFaq = [
    {
        title: 'Хто такий поручитель у студентському кредиті?',
        children: 'Поручитель – це особа, яка бере на себе юридичну відповідальність за погашення кредиту у разі, якщо позичальник не виконує свої зобов’язання.'
    },
    {
        title: 'Яка відповідальність поручителя, якщо позичальник не сплатив кредит?', 
        children: 'Якщо позичальник прострочує платіж або не сплачує кредит, поручитель зобов’язаний погасити заборгованість замість нього. Це включає основний борг, відсотки, штрафи та інші нарахування.'
    },
    {
        title: 'Чи вплине невиплата кредиту позичальником на кредитну історію поручителя?',
        children: 'Так, якщо поручитель не погасить борг замість позичальника, інформація про прострочення буде зафіксована в його кредитній історії. Це може знизити кредитний рейтинг поручителя та ускладнити отримання кредитів у майбутньому.'
    },
    {
        title: 'Що може зробити поручитель, якщо позичальник не сплачує кредит?',
        children: <div>
            Поручитель може:
            <ul className="ul">
                <li>Звернутися до позичальника з проханням погасити заборгованість.</li>
                <li>Сплатити борг самостійно, щоб уникнути штрафів та негативних наслідків для
                своєї кредитної історії.</li>
                <li>Домовитися з кредитором про реструктуризацію боргу.</li>
            </ul>
        </div>
    },
    {
        title: 'Чи може поручитель відмовитися від відповідальності після підписання договору?',
        children: 'Ні, після підписання договору поручитель не може відмовитися від своїх зобов’язань. Відповідальність припиняється лише після повного погашення кредиту.'
    },
    {
        title: 'Чи може поручитель подати в суд на позичальника за несплату?',
        children: 'Так, поручитель може звернутися до суду з вимогою компенсувати збитки, якщо він сплатив борг замість позичальника. Це дозволяє відшкодувати витрати, пов’язані з погашенням кредиту.'
    },
    {
        title: 'Чи повідомлять поручителя про прострочення платежу?',
        children: 'Зазвичай кредитор інформує поручителя про прострочення платежів позичальником. Це дозволяє поручителю вчасно вжити заходів для уникнення додаткових штрафів.'
    },
    {
        title: 'Які ризики для поручителя при оформленні студентського кредиту?',
        children: <div>
            Основні ризики:
            <li>Фінансова відповідальність за борг.</li>
            <li>Погіршення кредитної історії у разі несплати.</li>
            <li>Юридичні наслідки, якщо борг не буде погашений.</li>
        </div>
    },
    {
        title: 'Чи може поручитель домовитися про відстрочку платежу?',
        children: 'Так, поручитель може звернутися до кредитора з проханням про відстрочку або реструктуризацію боргу. Умови залежать від політики кредитора.'
    },
    {
        title: 'Чи можна замінити поручителя під час дії кредиту?',
        children: <div>
            Це залежить від умов кредитного договору. У деяких випадках заміна поручителя
            можлива, але тільки за згодою кредитора.
            <br />
            <br />
            Ці відповіді допоможуть зрозуміти роль поручителя та його відповідальність. Завжди
            уважно читайте умови кредитного договору перед підписанням!
        </div>
    },
    {
        title: 'Що станеться, якщо я не здійснив платіж за кредитом вчасно?',
        children: 'Якщо платіж за студентським кредитом не був здійснений вчасно, це може призвести до нарахування штрафних відсотків, погіршення вашої кредитної історії та можливого звернення кредитора до колекторів у разі тривалого прострочення.'
    },
    {
        title: 'Чи є можливість отримати відстрочку платежу?',
        children: 'Так, багато кредитних установ пропонують програми відстрочки або реструктуризації боргу для студентів. Зверніться до вашого кредитора, щоб дізнатися, чи доступна ця опція у вашому випадку.'
    },
    {
        title: 'Як швидко я повинен здійснити платіж, щоб уникнути штрафів?',
        children: 'Більшість кредиторів надають "грейс-період" (декілька днів після дати платежу). Уточніть умови у вашій кредитній угоді. Після закінчення цього періоду можуть бути нараховані штрафи.'
    },
    {
        title: 'Як штрафи вплинуть на мій борг?',
        children: 'Штрафи збільшують загальну суму боргу. Також, якщо ви прострочуєте кілька платежів, загальна сума боргу може зрости значно більше, ніж очікувалося.'
    },
    {
        title: 'Чи повідомлять моїх батьків або університет про прострочення платежу?',
        children: 'У більшості випадків кредитор не повідомляє університет чи ваших батьків. Проте, це може бути залежним від умов кредитного договору.'
    },
    {
        title: 'Чи вплине прострочення на мою кредитну історію?',
        children: 'Так, прострочення платежу буде зафіксовано в кредитній історії. Це може ускладнити отримання кредитів у майбутньому.'
    },
    {
        title: 'Що робити, якщо я не можу сплатити зараз?',
        children: 'Якнайшвидше зв’яжіться з вашим кредитором. Багато установ готові запропонувати рішення, наприклад, зменшення платежів або реструктуризацію боргу.'
    },
    {
        title: 'Чи можу я отримати консультацію щодо управління боргами?',
        children: 'Так, існують спеціалізовані фінансові консультації, які допомагають студентам впоратися з боргами. Ви також можете звернутися до кредитора за рекомендаціями.'
    },
    {
        title: 'Які юридичні наслідки можливі за тривале прострочення?',
        children: <div>
            Якщо борг не погашається протягом тривалого часу, кредитор може звернутися до суду
            або передати ваш борг колекторській компанії. Це може мати серйозні юридичні наслідки.
            <br />
            <b>Як уникнути подібних ситуацій у майбутньому?</b>
            <br /><br />
            Плануйте свій бюджет, встановіть нагадування про дати платежів та звертайтесь за
            фінансовою консультацією, якщо бачите, що можуть виникнути труднощі зі сплатою.
            <br /><br />
            Якщо потрібна додаткова інформація або допомога, завжди звертайтесь до вашого
            кредитора!
        </div>
    },
    {
        title: 'Які умови надання кредиту на навчання на IT ,професійних,мовних курсах?',
        children: <div>
            Наша фінансова компанія пропонує кредитування для IT курсів на наступних умовах:
            <ul className="ul">
                <li>Сума кредиту: від 10 000 грн до 50 000 грн.</li>
                <li>Строк кредитування: від 1 до 6 місяців.</li>
                <li>Валюта кредиту: гривня (₴).</li>
                <li>Оформлення кредиту: безкоштовне.</li>
                <li>Погашення: рівними щомісячними платежами.</li>
                <li>Потрібен получитель - це можуть бути батьки,рідні ,друзі</li>
                <li>Перерахування коштів: на рахунок чи картку в українському банку.</li>
            </ul>
            Ми надаємо можливість швидко та без зайвих витрат оформити кредит для вашого навчання.
        </div>
    },
    {
        title: 'Який термін кредитування доступний і чи можу я обрати період від 1 до 6 місяців?',
        children: 'Термін кредитування залежить від тривалості вашого навчання. Ви можете обрати період від 1 до 6 місяців, який відповідає строку ваших курсів. Під час подачі заявки на кредит вам необхідно додати рахунок-фактуру від IT-курсів або мовних курсів, де зазначено термін навчання'
    },
    {
        title: "Чи обов'язково потрібен поручитель для отримання кредиту?",
        children: 'Зазвичай ми надаємо цей тип кредиту з поручителем, оскільки це загальноприйнята практика в усьому світі. Багато людей, які звертаються за таким кредитом, тільки починають свій шлях у новій професії і ще не мають стабільного доходу. Однак, якщо у заявника хороша кредитна історія, він може подати заявку без поручителя.'
    },
    {
        title: 'Як здійснюється погашення кредиту і яка сума щомісячних платежів?',
        children: <div>
            Погашення кредиту здійснюється щомісяця рівними платежами. Коли ви введете свої дані в кредитний калькулятор, ви можете натиснути кнопку "<b>Показати графік платежів</b>". Графік платежів покаже дати та суми ваших майбутніх платежів.
        </div>
    },
    {
        title: 'Які документи необхідні для оформлення студентського кредиту?',
        children: <div>
            Для оформлення студентського кредиту вам необхідно надати такі документи:
            <ul className="ul">
                <li>Заяву на отримання кредиту, яку ви можете заповнити на нашому вебсайті.</li>
                <li>Рахунок-фактуру від курсів або коледжу, де зазначено вартість і тривалість навчання.</li>
                <li>Інформацію про поручителя</li>
            </ul>
            Надання цих документів дозволить нам швидко розглянути вашу заявку та запропонувати найкращі умови кредитування.
        </div>
    },
    {
        title: 'Чи можу я погасити кредит достроково без штрафних санкцій?',
        children: "Так, ви можете погасити кредит достроково без штрафних санкцій. Ми пропонуємо гнучкі умови для студентських кредитів, що дозволяють вам погасити позику без додаткових платежів чи штрафів. Це дає вам можливість зменшити загальну вартість кредиту та швидше завершити фінансові зобов'язання."
    }
]