import React from 'react'

import Footer from '../../components/Footer/Footer'
import FAQ from '../../components/FAQ/FAQ'
import StudyLoanWhyProfitableTakeLoan from '../components/StudyLoan/StudyLoanWhyProfitableTakeLoan'
import StudyLoanToGetLoan from '../components/StudyLoan/StudyLoanToGetLoan'
import StudyLoanCalculate from '../components/StudyLoan/StudyLoanCalculate'
import ApplicationForm from '../../components/Forms/ApplicationForm'
import StudyLoanLeaveRequest from '../components/StudyLoan/StudyLoanLeaveRequest'
import 'swiper/css'
import Reviews from '../../components/Reviews/Reviews'
import StudyLoanSimpleSteps from "../components/StudyLoan/StudyLoanSimpleSteps";
import { studyLoanFaq } from './faqHelper'

const StudyLoan = () => {
    return (
        <div className="study_loan">
            {/* Calculator */}
            <StudyLoanCalculate />

            {/* Why is it profitable to take a loan? */}
            <StudyLoanWhyProfitableTakeLoan />

            {/* Application form */}
            <ApplicationForm />

            {/* To get a loan */}
            <StudyLoanToGetLoan />

            <StudyLoanSimpleSteps />

            <StudyLoanLeaveRequest />

            <Reviews
                list={[
                    { sender: 'Олексій', text: 'Я вирішив змінити професію та вступити на довготривалі курси з IT, але зіткнувся з фінансовими труднощами. Банк відмовив у наданні кредиту, і я вже не знав, що робити. На щастя, в інтернеті я натрапив на цей сервіс Groshi 247  і вирішив спробувати. Усе виявилося дуже просто – заявку на кредит для студентів я оформив онлайн, а кошти на картку отримав у найкоротший термін. Умови прозорі, відсотки доступні, а графік погашення зручний. Завдяки цьому сервісу я зміг оплатити курси та тепер маю шанс реалізувати свої мрії в новій професії. Дуже вдячний і рекомендую всім, хто опинився у схожій ситуації', isShowBtn: true },
                    { sender: 'Інна', text: 'Хочу поділитися позитивним досвідом! Вирішила вивчати англійську для кар\'єрного росту, але курси виявилися досить дорогими. Завдяки сервісу Groshi 247 змогла отримати кредит без зайвих затримок. Усе прозоро, швидко і дуже зручно. Курси вже оплачено, і тепер я рухаюся до своїх цілей. Велике дякую за допомогу', isShowBtn: false },
                    { sender: 'Андрій', text: 'Я переїхав із сходу країни та вирішив змінити професію – хочу стати вебдизайнером. На початку навчання мав гроші лише на перший семестр, сподівався зібрати необхідну суму з допомогою друзів, але не вдалося. Ситуація стала критичною – без оплати я б не зміг продовжити навчання. На щастя, я знайшов цей сервіс Groshi 247 в інтернеті. Після розмови з менеджером вони запропонували варіант із додаванням поручителя, і один із моїх друзів погодився допомогти. Завдяки цьому я отримав необхідні кошти та зміг продовжити навчання. Дуже вдячний за таку можливість! Сподіваюся завершити навчання та знайти хорошу роботу. Дякую вам за підтримку у складний момент', isShowBtn: true },
                    { sender: 'Лера', text: 'Я завжди любила собачок і мріяла про роботу, пов’язану з доглядом за домашніми улюбленцями. Але мені бракувало знань та навичок для цього. Зараз я працюю касиром у супермаркеті, але дуже хотіла змінити своє життя. Знайшла чудову школу, де навчають догляду за домашніми тваринами, але потрібні були гроші, щоб оплатити навчання. Моя сестра знайшла цей сервіс в інтернеті, і завдяки їм ми отримали кредит на мій курс. Сестра стала поручителем, і все пройшло дуже легко та швидко. Тепер я щаслива, що зможу реалізувати свою мрію та працювати улюблену справу завдяки цій допомозі. Дякую від щирого серця', isShowBtn: true }
                ]}
            />

            <FAQ
                list={studyLoanFaq}
            />

            <Footer />
        </div>
    );
};

export default StudyLoan;