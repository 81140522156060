import { useEffect, useState } from "react";

import Button from "../Common/Button";
import privacyPolicyFile from "../../files/privacy_policy.pdf";
import { LS_KEY_AGREE_WITH_COOKIES } from "../../const/lsKeys";

import "./Cookies.scss";

const Cookies = () => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const agreeWithCookies = localStorage.getItem(
            LS_KEY_AGREE_WITH_COOKIES
        );

        if (agreeWithCookies === null) {
            localStorage.setItem(LS_KEY_AGREE_WITH_COOKIES, "false");
            setOpen(true);
            return;
        }

        if (agreeWithCookies === "false") {
            setOpen(true);
        }
    }, []);

    const onClick = () => {
        localStorage.setItem(LS_KEY_AGREE_WITH_COOKIES, "true");
        setOpen(false);
    };

    return (
        <div
            className={`cookies-snackbar ${
                open ? "cookies-snackbar_visible" : ""
            }`}
        >
            <div className="cookies-snackbar__inner-wrapper">
                <p>
                    Ми використовуємо cookie, щоб зберігати дані та формувати
                    для вас найкращі пропозиції. Залишаючись на нашому сайті, ви
                    погоджуєтеся з цією умовою. Детальніше - в{" "}
                    <a href={privacyPolicyFile} target="_blank" download>
                        умовах конфіденційності
                    </a>
                </p>
                <Button green onClick={onClick}>
                    Добре
                </Button>
            </div>
        </div>
    );
};

export default Cookies;
