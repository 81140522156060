import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'

import formsIcon from '../../../assets/forms.png'
import documentAccessIcon from '../../../assets/document_access.png'
import registrationIcon from '../../../assets/registration.png'
import smileHappyIcon from '../../../assets/smile_happy.png'

const PaydayLoanHowGet = () => {
	return (
		<div className="payday_loan_how_get">
			<div className="container">
				<div className="title">Прості кроки для отримання кредиту</div>
				<Swiper
					spaceBetween={10}
					slidesPerView={1}
					className="payday_loan_how_get__swiper"
					breakpoints={{
						500: {
							slidesPerView: 1,
							spaceBetween: 20,
						},
						550: {
							slidesPerView: 3,
							spaceBetween: 40,
						},
						1024: {
							slidesPerView: 5,
							spaceBetween: 50,
						},
					}}
					onSlideChange={() => console.log('slide change')}
					onSwiper={(swiper) => console.log(swiper)}
				>
					<SwiperSlide>
						<div className="get_loan__item payday_loan_how_get__item">
							<div className="payday_loan_how_get__img">
								<img src={formsIcon} alt="form icon" />
							</div>
							<div className="payday_loan_how_get__text">
								Заповнити<br /> анкету
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="get_loan__item payday_loan_how_get__item">
							<div className="payday_loan_how_get__img">
								<img src={documentAccessIcon} alt="documentAccess icon" />
							</div>
							<div className="payday_loan_how_get__text">
								Отримати<br /> рішення
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="get_loan__item payday_loan_how_get__item">
							<div className="payday_loan_how_get__img">
								<img src={registrationIcon} alt="registration icon" />
							</div>
							<div className="payday_loan_how_get__text">
								Підписати договір<br /> кредиту
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="get_loan__item payday_loan_how_get__item">
							<div className="payday_loan_how_get__img">
								<img src={smileHappyIcon} alt="smileHappy icon" />
							</div>
							<div className="payday_loan_how_get__text">
								Отримати<br /> кошти
							</div>
						</div>
					</SwiperSlide>
				</Swiper>
				<div className="payday_loan_how_get__list">
					<div className="payday_loan_how_get__item">
						<div className="payday_loan_how_get__img">
							<img src={formsIcon} alt="form icon" />
						</div>
						<div className="payday_loan_how_get__text">
							Заповнити<br /> анкету
						</div>
					</div>
					<div className="payday_loan_how_get__item">
						<div className="payday_loan_how_get__img">
							<img src={documentAccessIcon} alt="documentAccess icon" />
						</div>
						<div className="payday_loan_how_get__text">
							Отримати<br /> рішення
						</div>
					</div>
					<div className="payday_loan_how_get__item">
						<div className="payday_loan_how_get__img">
							<img src={registrationIcon} alt="registration icon" />
						</div>
						<div className="payday_loan_how_get__text">
							Підписати договір<br /> кредиту
						</div>
					</div>
					<div className="payday_loan_how_get__item">
						<div className="payday_loan_how_get__img">
							<img src={smileHappyIcon} alt="smileHappy icon" />
						</div>
						<div className="payday_loan_how_get__text">
							Отримати<br /> кошти
						</div>
					</div>
				</div>
				<div className="payday_loan_how_get__btn_block">
					<button className="btn btn__pink">
						<Link
							to="https://cab.groshi247.com/signup"
							target="_blank"
							rel="noopener noreferrer"
						>
							Оформити кредит
						</Link>
					</button>
				</div>
			</div>
		</div>
	);
};

export default PaydayLoanHowGet;